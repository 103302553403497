<template>
  <div class="content-header-global  align-items-center">
    <p class="txt-title-header poppins mb-0">{{sNameHeader}}</p>
    <v-spacer />
    <v-btn v-if="bAdminEmployees && bBtnAdd" @click="AddGlobal" class="button-primary poppins" elevation="0">
      <span v-if="!responsive">{{sNameBtnAdd}}</span>
      <v-icon v-else color="#fff" size="18px">
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>
    <div class="content-message">
      Información actualizada por ultima vez 20/dic/2022 a las 12:30pm
    </div>
  </div>
</template>

<script>

export default {
  name: "HeaderComponentGlobal",
  props: {
    sNameHeader: String,
    bBtnAdd: Boolean,
    sNameBtnAdd: String,
  },
  data() {
    return {
      responsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.responsive = false;
      } else {
        this.responsive = true;
      }
    },
    AddGlobal() {
      // this.$emit("AddGlobal")
    },
  },
  computed: {
    bAdminEmployees() {
      return this.$store.state.bAdminEmployees
    }
  }
};
</script>

<style scoped>
.content-header-global {
  display: flex;
}

.txt-title-header {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-buttons-and-message {
  display: flex;
  align-items: center;
}

.content-message {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2759a2;
  border-left: 5px solid #C4DCFF;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #2759a2;
}


/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-header-global {
    display: block;
  }

}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-header-global {
    display: block;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */

@media (max-width: 600px) {
  .txt-title {
    font-size: 25px;
  }

  .content-buttons,
  .content-buttons-and-message {
    display: block;
    width: 100%;
  }
}
</style>